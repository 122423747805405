/* styles/Waiting.css */
.container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f5f5f5; /* or any background color you prefer */
  }
  
  #waitingDiv {
    text-align: center;
  }
  
  #waitingDiv h1 {
    color: #333;
  }
  
  #waitingDiv p {
    font-size: 1.2em;
  }
  
  #waitingDiv a {
    color: #007bff;
    text-decoration: none;
  }
  
  #waitingDiv a:hover {
    text-decoration: underline;
  }
  
  #redirectButton {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 1em;
    cursor: pointer;
    margin-top: 20px;
  }
  
  #redirectButton:hover {
    background-color: #0056b3;
  }
  