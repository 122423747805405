.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #313338;
  color: #000000;
  font-family: 'Inter', sans-serif;
  margin: 0;
}

#createGroupDiv {
  width: 400px;
  padding: 40px;
  background-color: #A51F1F; 
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
  margin-top: 40px;
}

.inner-heading {
  color: #FFF3E8; 
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.inputField {
  width: 100%;
  height: 50px;
  background-color: #F0F0F0;
  border-radius: 8px;
  border: none;
  padding: 0 15px;
  margin-bottom: 10px;
  font-size: 16px;
  box-sizing: border-box;
}

.buttons {
  width: 100%;
  height: 50px;
  background-color: #FFF3E8;
  color: #A51F1F;
  border: none;
  border-radius: 8px;
  font-size: 18px;
  cursor: pointer;
  margin-top: 10px;
}

.buttons:hover {
  background-color: #E0E0E0;
}

.message {
  color: #000000;
  font-size: 14px;
  margin-top: 10px;
}

a {
  color: #FFD700;
  text-decoration: none;
}

#signupLink {
  text-decoration: underline;
  color: #FFD700;
}

.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 20px;
  z-index: 1000;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  color: #000000; /* Text color set to black */
}

.popup button {
  margin-top: 10px;
}
