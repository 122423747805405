body {
    background-color: #fafbff;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin: 0;
    font-family: 'Inter', sans-serif;
  }
  
  .container {
    text-align: center;
    color: #060300;
  }
  
  #loginDiv {
    width: 400px;
    padding: 40px;
    background-color: #A51F1F;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    text-align: center;
    margin-top: 40px;
  }
  
  #inner-title {
    font-size: 24px;
    font-weight: bold;
    color: #FFF3E8;
    margin-bottom: 20px;
  }
  
  input[type="email"],
  input[type="password"] {
    width: 100%;
    height: 50px;
    background-color: #F0F0F0;
    border-radius: 8px;
    border: none;
    padding: 0 15px;
    margin-bottom: 10px;
    font-size: 16px;
    box-sizing: border-box;
  }
  
  #loginButton {
    width: 100%;
    height: 50px;
    background-color: #FFF3E8;
    color: #A51F1F;
    border: none;
    border-radius: 8px;
    font-size: 18px;
    cursor: pointer;
    margin-top: 10px;
  }
  
  #loginButton:hover {
    background-color: #E0E0E0;
  }
  
  #loginResult {
    color: #000000;
    font-size: 14px;
    margin-top: 10px;
  }
  
  a {
    color: #FFD700;
    text-decoration: none;
  }
  
  #signupLink {
    text-decoration: underline;
    color: #FFD700;
  }
  