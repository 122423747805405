.vote-page-container {
  position: relative;
  width: 100%;
  min-height: 100vh;
  background: #313338;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 20px;
}

.vote-header {
  color: #fff3e8;
  font-family: 'Inter', sans-serif;
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
}

.voter-head {
  color: #fff3e8;
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
}

.movies-list {
  width: 100%;
  max-width: 800px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 20px;
  overflow-y: auto; /* Enable vertical scrolling for movies */
  max-height: 60vh; /* Limit max height for scroll */
}

.movie-box {
  background-color: #a51f1f;
  color: #fff3e8;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  width: calc(33.33% - 20px); /* Adjust width as per your design */
  box-sizing: border-box;
  transition: transform 0.3s;
  margin-bottom: 20px; /* Added margin between movie boxes */
}

.movie-title {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vote-button {
  background-color: #a51f1f;
  color: #fff3e8;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 10px;
}

.vote-button:hover {
  background-color: #7e1313;
}

.navigation-bar {
  display: flex;
  justify-content: space-around;
  width: 100%;
  background: #000;
  padding: 10px 0;
  position: fixed;
  bottom: 0;
  left: 0;
}

.nav-item {
  width: 40px;
  height: 40px;
}

.nav-icon img {
  width: 100%;
  height: 100%;
}

.current-page img {
  opacity: 0.6;
}
