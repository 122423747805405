/* Ensure no margin or padding affects the container */
html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: hidden; /* Prevent scrollbars */
}

/* Container for the landing page */
.landing-container {
  position: relative;
  width: 100vw; /* Full viewport width */
  height: 100vh; /* Full viewport height */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: url('./landingpage.png') no-repeat center center;
  background-size: contain; /* Adjust image to fit within container */
  background-repeat: no-repeat; /* Prevent repeating the image */
  background-position: center; /* Center the image */
}

/* Header style to ensure it appears on top of the background image */
.landing-header {
  margin-bottom: 20px;
  text-align: center;
  position: absolute; /* Positioned on top of the background image */
  top: 20px; /* Adjust as needed for vertical positioning */
  width: 100%; /* Full width */
  z-index: 2; /* Ensure header is above other content */
}

/* Title style */
.landing-title {
  color: #ffffff; /* White text */
  font-family: 'Inter', sans-serif; /* Font family */
  font-size: 36px; /* Font size */
  font-weight: bold; /* Font weight */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Optional text shadow for readability */
}

/* Main content container */
.landing-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative; /* Positioned below the header */
  z-index: 1; /* Ensure buttons are above the background image */
}

/* Link styling */
.landing-link {
  text-decoration: none; /* Remove underlines from links */
}

/* Button styling */
.landing-button {
  margin: 10px; /* Space around buttons */
  padding: 12px 24px; /* Button padding */
  font-size: 18px; /* Font size */
  background-color: #a51f1f; /* Red button background */
  color: #fff3e8; /* Button text color */
  border: none; /* Remove border */
  border-radius: 8px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.3s ease; /* Smooth transition for hover effect */
}

/* Button hover effect */
.landing-button:hover {
  background-color: #7e1313; /* Darker red on hover */
}
