.profile-page-container {
  position: relative;
  width: 100%;
  min-height: 100vh;
  background: #313338;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; /* Centering vertically */
  padding: 20px;
}

.profile-header {
  color: #fff3e8;
  font-family: 'Inter', sans-serif;
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
}

.profile-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.profile-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.profile-image {
  width: 250px; /* Increased size */
  height: 250px; /* Increased size */
  border-radius: 50%;
  background-color: #a51f1f;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  margin-top: -40px; /* Adjusted positioning */
}

.profile-placeholder {
  font-size: 80px; /* Adjusted size */
  color: #fff3e8;
}

.profile-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.username,
.email,
.party-name {
  font-size: 28px;
  color: #fff3e8;
  text-align: center; /* Centered text */
  margin-bottom: 10px;
}

.button-space {
  display: flex;
  flex-direction: column; /* Stack buttons vertically */
  gap: 10px; /* Spacing between buttons */
}

.profile-button {
  background-color: #a51f1f;
  color: #fff3e8;
  border: none;
  padding: 12px 24px;
  border-radius: 8px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: inline-block;
  text-align: center;
}

.profile-button:hover {
  background-color: #7e1313;
}

.navigation-bar {
  display: flex;
  justify-content: space-around;
  width: 100%;
  background: #000;
  padding: 10px 0;
  position: fixed;
  bottom: 0;
  left: 0;
}

.nav-item {
  width: 40px;
  height: 40px;
}

.nav-icon img {
  width: 100%;
  height: 100%;
}

.current-page img {
  opacity: 0.6;
}

.popup-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-container {
  background-color: #fff3e8;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.popup-container p {
  font-size: 18px;
  margin-bottom: 10px;
}

.confirmation-buttons {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.confirmation-buttons button {
  padding: 8px 16px;
  font-size: 16px;
  cursor: pointer;
}
