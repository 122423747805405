.change-password-container {
  position: relative;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 20px;
}

.change-password-header {
  color: #fff3e8;
  font-family: 'Inter', sans-serif;
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center; 
}

.change-password-content {
  width: 100%;
  max-width: 600px; 
  display: flex;
  flex-direction: column;
  align-items: center;
}

.change-password-form {
  width: 100%;
  padding: 20px;
  background-color: #a51f1f; 
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  margin-bottom: 20px;
}

.change-password-form label {
  margin-bottom: 8px;
  font-weight: bold;
  color: #fff3e8; 
}

.change-password-form input {
  width: calc(100% - 20px);
  padding: 10px;
  margin-bottom: 20px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
}

.change-password-form input[type="submit"] {
  background-color: #fff3e8; 
  color: #a51f1f;
  font-size: 18px;
  padding: 12px 24px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.change-password-form input[type="submit"]:hover {
  background-color: #e5e5e5;
}

.error-message {
  color: #fff3e8; 
  margin-bottom: 10px;
}

.back-to-profile {
  text-align: center;
  color: #fff3e8; /* White link text */
  margin-top: 20px;
}

.back-to-profile a {
  color: #fff3e8; /* White link text */
  text-decoration: none;
}

.back-to-profile a:hover {
  text-decoration: underline;
}
